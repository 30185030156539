import React, {useState} from "react";
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/ar';
import {IconButton} from "@material-ui/core";
import NextIcon from '@material-ui/icons/ChevronRight';
import PrevIcon from '@material-ui/icons/ChevronLeft';
import {useMountEffect} from "../../util/Global";
import {accessToken, getUser} from "../../util/UserPrefs";
import {SERVER} from "../../index";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import EventView from "./EventView";

const localizer = momentLocalizer(moment)

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },

}));
export default function EventsView({onDateSelect}) {
    const classes = useStyles();

    const arabic = localStorage.getItem("dir") === "rtl";
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const user = getUser();

    useMountEffect(() => {
        if (user == null) {
            return;
        }
        setLoading(true);
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken()}`);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER}/events/user/${user.id}`, {
            headers: headers,
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setLoading(false);
                if (Array.isArray(data)) {
                    setEvents(transformEvents(data));
                }

            })
            .catch(e => {
                if (!aborted) {
                    setLoading(false);
                }
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    });
    return <div className={classes.root}>
        {loading ? <CircularProgress size={100} color={'secondary'}/> :
            <Calendar
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                events={events}
                culture={arabic ? "ar" : "en"}
                views={["month"]}
                rtl={arabic}
                selectable
                onSelectSlot={slotInfo => {
                    let e = [];
                    if (events.length > 0) {
                        e = events.filter(event => (event.start.toLocaleDateString() === slotInfo.start.toLocaleDateString()))
                    }
                    if (e.length > 0) {
                        onDateSelect(slotInfo.start.toLocaleDateString());
                    }
                }
                }
                onSelectEvent={event => {
                    onDateSelect(event.start.toLocaleDateString())
                }
                }
                style={{height: '80vh', width: '100%'}}
                components={{
                    toolbar: Toolbar,
                    event: EventView
                }
                }
            />
        }
    </div>
}

function transformEvents(events) {
    let seen = [];
    let real = [];
    events.forEach(e => {
        let date = new Date(e.dateOfEvaluation);
        if (seen.indexOf(date.toLocaleDateString()) === -1) {
            real.push({
                title: e.name,
                start: date,
                end: date,
                comment: e.comments,
                allDay: false,
            });
            seen.push(date.toLocaleDateString());
        }
    })
    return real;
}

function Toolbar({label, onNavigate}) {
    const arabic = localStorage.getItem("dir") === "rtl";
    const navigate = action => {
        onNavigate(action)
    }
    return (
        <div className="rbc-toolbar">
            <IconButton onClick={navigate.bind(null, "PREV")}>{arabic ? <NextIcon/> : <PrevIcon/>}</IconButton>
            <span className="rbc-toolbar-label">{label}</span>
            <IconButton
                color={'secondary'}
                onClick={navigate.bind(null, "NEXT")}>{arabic ? <PrevIcon/> : <NextIcon/>}</IconButton>
        </div>
    )
}
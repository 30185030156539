import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {Button, DialogContentText, Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import LogoView from "../../common/view/LogoView";
import {validateEmail} from "../../util/Global";
import {SERVER} from "../../index";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    formDiv: {
        maxWidth: 500,
        textAlign: "center",
        margin: "0 auto",
    },
    field: {
        margin: theme.spacing(2),
    },
    submitButton: {
        margin: theme.spacing(2),
        maxWidth: 300,
        color: '#FFF',
    }
}));
export default function EmailInputView({onDone}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [email, setEmail] = useState(null);
    const [result, setResult] = useState({text: "", error: false, loading: false});
    const [dialog, setDialog] = useState({open: false, message: ""});

    const onChange = name => event => {
        if (name === "email") {
            setEmail(event.target.value);
        }
    };
    const onSave = () => {
        if (!validateEmail(email)) {
            setResult({text: t('error_input_email'), error: true, loading: false});
            return;
        }
        sendCode();
    }

    const sendCode = () => {

        setResult({text: "", error: false, loading: true});

        fetch(`${SERVER}/users/sendCode/${email}`, {
            method: "PATCH",
        })
            .then(response => response.json())
            .then(() => {
                setResult({loading: false, error: false, text: ""});
                setDialog({open: true, message: t('resend_code_reset')});
            }).catch(e => {
            setResult({loading: false, error: true, text: t('error_reset_password')});
        })
    }

    const onDialogClose = () => {
        setDialog({open: false, message: ""});
        onDone(email)
    };

    return <div className={classes.root}>
        <LogoView title={t('reset_password')}/>
        <div className={classes.formDiv}>

            <TextField variant={'filled'}
                       className={classes.field}
                       color={'secondary'}
                       value={email}
                       required
                       type={'email'}
                       disabled={result.loading}
                       fullWidth
                       label={t('email')}
                       onChange={onChange("email")}
                       name={"email"}/>

            <Typography variant={'body1'} color={result.error ? "error" : "textPrimary"}>{result.text}</Typography>
            <Button
                fullWidth
                onClick={onSave}
                disabled={result.loading}
                className={classes.submitButton}
                color={'secondary'} variant={'contained'}>
                {result.loading && <CircularProgress color={'secondary'}/>}{t('reset_password')}
            </Button>
        </div>
        <Dialog open={dialog.open}
                onClose={onDialogClose}>
            <DialogContent>
                <DialogContentText id={'response-dialog-text'}>
                    {dialog.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={onDialogClose}>
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    </div>

}

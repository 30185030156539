import moment from "moment";
import React, {useState} from "react";
import HijriUtils from "@date-io/hijri";
import {DatePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import "moment/locale/ar-sa";
import pickerTheme from "../common/pickerTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Button, Typography} from "@material-ui/core";
import LogoView from "../common/view/LogoView";
import MomentUtils from "@date-io/moment";
import hijriMoment from "moment-hijri";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(2),
        color: "#FFF"
    },
    formDiv: {
        maxWidth: 500,
        textAlign: "center",
        margin: "0 auto",
    },
    picker: {
        display: 'none',
    },
    result: {
        fontWeight: 'bold',
        fontSize: "larger",
    }

}));

export default function ConvertDateView() {
    const [selectedDate, handleDateChange] = useState(moment());
    const [enOpen, setEnOpen] = useState(false);
    const [hijriOpen, setHijriOpen] = useState(false);
    const [result, setResult] = useState({hijri: "", english: ""});

    const classes = useStyles();
    const {t} = useTranslation();

    const openArabic = () => {
        selectedDate.locale("ar-SA");
        setHijriOpen(true);
    }
    const openEnglish = () => {
        selectedDate.locale("en");
        setEnOpen(true);
    }
    const onAccept = (selectedDate) => {

        handleDateChange(selectedDate);
        setHijriOpen(false);
        setEnOpen(false);
        let date = hijriMoment(selectedDate);
        date.locale("ar-SA");
        let hijri = date.format("iD iMMMM iYYYY");
        selectedDate.locale("en");
        let gre = selectedDate.format("DD MMMM YYYY");
        setResult({hijri: hijri, english: gre})
    }
    return (
        <div className={classes.root}>
            <LogoView title={t('convert_date')}/>
            <div className={classes.formDiv}>
                <div>
                    <Button className={classes.button} color={'secondary'}
                            onClick={openEnglish}
                            variant={'contained'}>{t('select_gregorian_date')}</Button>
                </div>
                <div>
                    <Button className={classes.button} color={'secondary'}
                            onClick={openArabic}
                            variant={'contained'}>{t('select_hijri_date')}</Button>

                </div>

                <ThemeProvider theme={pickerTheme}>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale={'en'}>
                        <DatePicker
                            open={enOpen}
                            className={classes.picker}
                            okLabel={t('ok')}
                            cancelLabel={t("cancel")}
                            value={selectedDate}
                            onAccept={onAccept}
                            onChange={handleDateChange}
                            minDate="1937-03-14"
                            maxDate="2076-11-26"
                        />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={HijriUtils} locale={'ar-SA'}>
                        <DatePicker
                            open={hijriOpen}
                            className={classes.picker}
                            okLabel={t('ok')}
                            cancelLabel={t("cancel")}
                            value={selectedDate}
                            onAccept={onAccept}
                            onChange={handleDateChange}
                            minDate="1937-03-14"
                            maxDate="2076-11-26"
                        />
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
                <div>
                    <Typography variant={'body1'} className={classes.result}>{result.hijri}</Typography>
                </div>
                <div>
                    <Typography variant={'body1'} className={classes.result}>{result.english}</Typography>
                </div>

            </div>
        </div>

    );
}

import React from 'react'
import {Map, Marker, Popup, TileLayer} from 'react-leaflet'

export default function MapView({lat, lng, title, ...props}) {
    return <Map center={[lat, lng]} zoom={13} {...props}>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        />
        <Marker position={[lat, lng]}>
            <Popup>{title}</Popup>
        </Marker>
    </Map>
}
import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import logo from '../../images/logo_transparent.png'
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    logo: {
        textAlign: 'center',
        margin: "0 auto",
    }
}));
export default function LogoView({title}) {
    const {t} = useTranslation();
    const classes = useStyles();

    return <div className={classes.root}>
        <div>
            <div className={classes.logo}>
                <img src={logo} alt={t('app_name')} width={100} height={130}/>
            </div>
            <Typography variant={'h3'}>{title}</Typography>
        </div>
    </div>

}
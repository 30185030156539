import {createMuiTheme} from "@material-ui/core";

export default createMuiTheme({
    direction: localStorage.getItem('dir') === null ? 'ltr' : localStorage.getItem('dir'),
    palette: {
        primary: {
            main: '#54a9db',
        },
        secondary: {
            main: '#54a9db',
        },
    },
});
import React from "react";
import {SwipeableDrawer} from "@material-ui/core";
import HomeLayout from "./HomeLayout";
import {makeStyles} from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        width: 300,
        overflowX: "hidden",
        scrollbarWidth: 'thin',
    }
}));

export default function AppsDrawer({open, toggleDrawer, onChange}) {
    const classes = useStyle();

    return <SwipeableDrawer onClose={toggleDrawer(false)}
                            className={classes.root}
                            anchor={'right'}
                            onOpen={toggleDrawer(true)} open={open}>
        <div className={classes.root}>
            <HomeLayout onChange={onChange} sideBar/>
        </div>
    </SwipeableDrawer>
}
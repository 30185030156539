import React from "react";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    embed: {
        width: '100%',
        textAlign: "center",
        margin: "0 auto",
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
}));

export default function Curve() {
    const classes = useStyles();
    return (<div className={classes.root}>
        <embed src={"https://psyapp.net/cr/curve.html"}
               height={600}
               className={classes.embed}/>
    </div>);
}
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AboutIcon from '@material-ui/icons/Info';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import {useTranslation} from "react-i18next";
import UserAvatar from '../images/user.png';
import '../css/drawer.css';
import {getUser} from "../util/UserPrefs";
import Button from "@material-ui/core/Button";
import LoginDialog from "../user/LoginDialog";
import LogoutDialog from "../user/LogoutDialog";
import SignUpDialog from "../user/SignUpDialog";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

function listItem(text, icon, onClick) {
    return <ListItem button key={text} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text}/>
    </ListItem>
}

export default function AppDrawer({open, toggleDrawer, onAboutClick, onForgotPasswordClick}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const user = getUser();
    const [loginOpen, setLoginOpen] = useState(false);
    const [signupOpen, setSignupOpen] = useState(false);
    const [logoutOpen, setLogoutOpen] = useState(false);
    const list = (anchor) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}>
            <List>
                {listItem(t('about_us'), <AboutIcon/>, onAboutClick)}
                {user != null && listItem(t('logout'), <LogoutIcon/>, openLogout)}
            </List>
        </div>
    );

    const head = () => {
        return <div className={'header'}>
            <img alt={'user'}
                 src={user != null && user.image != null && user.image.length > 5 ? user.image : UserAvatar}
                 className={'user-image'}/>
            {user == null ?
                <div>
                    <Button
                        onClick={openLogin}
                        color={'secondary'}>
                        {t('login')}
                    </Button>
                    <br/>
                    <Button
                        onClick={openSignup}
                        color={'secondary'}>
                        {t('signup')}
                    </Button>
                </div> :
                <div>
                    <div>
                        <span id={'user-name'}>{user.name}</span>
                    </div>
                    <span id={'user-email'}>{user.email}</span>
                </div>
            }
        </div>
    }

    const openLogin = () => {
        toggleDrawer('left', false)();
        setLoginOpen(true);
    }
    const openSignup = () => {
        toggleDrawer('left', false)();
        setSignupOpen(true);
    }

    const openLogout = () => {
        toggleDrawer('left', false)();
        setLogoutOpen(true);
    }
    const onLoginResponse = () => {
        setLoginOpen(false);
    }
    const onSignupResponse = () => {
        setSignupOpen(false);
    }
    const onLogoutResponse = () => {
        setLogoutOpen(false);
    }

    return (
        <div>
            <SwipeableDrawer
                anchor={"left"}
                open={open || false}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                {head()}
                {list('left')}
            </SwipeableDrawer>
            <LoginDialog open={loginOpen} onResponse={onLoginResponse}
                         onForgotPassword={() => {
                             setLoginOpen(false);
                             onForgotPasswordClick()
                         }}/>
            <SignUpDialog open={signupOpen} onResponse={onSignupResponse}/>
            <LogoutDialog open={logoutOpen} onResponse={onLogoutResponse}/>
        </div>
    );
}

import {createMuiTheme} from '@material-ui/core/styles';

export const AppTheme = createMuiTheme({
    direction: localStorage.getItem('dir') === null ? 'ltr' : localStorage.getItem('dir'),
    palette: {
        primary: {
            main: '#FFFFFF',
        },
        secondary: {
            main: '#54a9db',
        },
    },
});
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {useMountEffect} from "../../util/Global";
import {SERVER} from "../../index";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '100%',
        maxWidth: 700,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    card: {
        margin: theme.spacing(1),
    },
    title: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'block',
        textOverflow: 'ellipsis',
    }
}));


export default function NewsGridView({path, onClick}) {
    const classes = useStyles();
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false);
    useMountEffect(() => {


        setLoading(true);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER}/${path}/get?sort=time%2CDESC&size=10000`, {
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(({content}) => {
                setLoading(false);
                if (Array.isArray(content)) {
                    setNews(content);
                }
            })
            .catch(e => {
                if (!aborted) {
                    setLoading(false);
                }
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    });

    return (
        <div className={classes.root}>
            {loading ? <CircularProgress size={100} color={'secondary'}/> :
                <GridList cellHeight={250} cols={1} className={classes.gridList}>
                    {news.map((tile) => (
                        <GridListTile key={tile.id}>
                            <Card className={classes.card} onClick={() => onClick(tile)}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        alt={tile.title}
                                        height="150"
                                        image={tile.image}
                                        title={tile.title}
                                    />
                                    <CardContent color={'secondary'}>
                                        <Typography variant="h5" component="h2" className={classes.title}>
                                            {tile.title}
                                        </Typography>
                                        <Typography variant={'body2'} color={'textSecondary'} component={'p'}>
                                            {new Date(tile.time).toLocaleString()}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </GridListTile>
                    ))}
                </GridList>
            }
        </div>
    );
}

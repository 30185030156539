export function saveUser(user) {
    localStorage.setItem("psy.user.email", user.email);
    localStorage.setItem("psy.user.name", user.name);
    localStorage.setItem('psy.user.phone', user.username);
    localStorage.setItem('psy.user.image', user.image);
    localStorage.setItem("psy.user.token", user.accessToken);
    localStorage.setItem("user", "true");
    localStorage.setItem('psy.user.id', user.id);
}

export function getUser() {
    const saved = localStorage.getItem("user") === "true";
    if (!saved) {
        return null;
    }
    return {
        email: localStorage.getItem("psy.user.email"),
        name: localStorage.getItem("psy.user.name"),
        phone: localStorage.getItem("psy.user.phone"),
        image: localStorage.getItem('psy.user.image'),
        id: localStorage.getItem('psy.user.id'),
    }
}

export function logout() {
    localStorage.removeItem("user")
    localStorage.removeItem("psy.user.email");
    localStorage.removeItem("psy.user.name");
    localStorage.removeItem("psy.user.phone");
    localStorage.removeItem("psy.user.token");
    localStorage.removeItem("psy.user.image");
    localStorage.removeItem('psy.user.id');
}

export function accessToken() {
    return localStorage.getItem("psy.user.token")
}
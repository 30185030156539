import React from 'react';
import {Button, Dialog, DialogActions} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useTranslation} from "react-i18next";
import {logout} from "../util/UserPrefs";


export default function LogoutDialog({open, onResponse}) {
    const {t} = useTranslation();


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {

        }
    };

    const handleLogout = () => {
        logout();
        onResponse(true);
    }

    return (
        <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
            <DialogTitle
                id="form-dialog-title">{t('logout')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("logout_message")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant={'text'} color={'secondary'}
                        onClick={() => {
                            onResponse(false);
                        }}>
                    {t("cancel")}
                </Button>
                <Button variant={'text'}
                        color={'secondary'}
                        onClick={handleLogout}>
                    {t("logout")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {accessToken} from "../../util/UserPrefs";
import ReactPlayer from "react-player";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(() => ({
    root: {
        padding: 0,
        margin: 0,
        overflowY: 'hidden',
        overflowX: 'hidden',
    },
}))(MuiDialogContent);

export function PreviewPlayDialog({course, open, onClose}) {

    return (
        <Dialog open={open}
                onClose={onClose}>
            <DialogTitle id="customized-dialog-title" onClose={onClose}>
                {course != null ? course.title : ""}
            </DialogTitle>
            <DialogContent dividers>
                {course != null && <ReactPlayer
                    height={'100%'}
                    width={'100%'}
                    controls
                    url={`${course.preview}?auth=${accessToken()}`}/>}
            </DialogContent>
        </Dialog>
    )
}
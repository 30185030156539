import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import en from "./en";
import ar from "./ar";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: {
                    ...en
                }
            },
            ar: {
                translations: {
                    ...ar
                }
            }
        },
        supportedLngs: ["en", "ar"],
        fallbackLng: "ar",
        debug: false,
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;

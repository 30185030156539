import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {accessToken, getUser} from "../../util/UserPrefs";
import {useMountEffect} from "../../util/Global";
import {SERVER} from "../../index";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Button, Fab} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {useTranslation} from "react-i18next";
import ComingSoon from "react-coming-soon";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 700,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        color: "#FFF",
        zIndex: 10000,
    },

    card: {
        maxWidth: 300,
        margin: theme.spacing(1)
    },
    media: {
        height: 100,
    },
    heading: {
        height: 30,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    subtitle: {
        height: 18,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));


export default function BooksGridView({onAdd, test}) {
    const classes = useStyles();
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState({open: false, book: {}});
    const user = getUser();
    const {t} = useTranslation();

    useMountEffect(() => {
        if (user == null) {
            return;
        }
        setLoading(true);
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken()}`);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER}/books/user/${test ? 'test/' : ''}${user.id}`, {
            headers: headers,
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setLoading(false);
                if (Array.isArray(data)) {
                    setBooks(data);
                }

            })
            .catch(() => {
                if (!aborted) {
                    setLoading(false);
                }
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    });

    const handleDeleteClose = (shouldDelete) => {
        if (shouldDelete) {
            let index = books.findIndex(book => book.id === deleteOpen.book.id);
            if (index > -1) {
                deleteBook(deleteOpen.book)
                books.splice(index, 1);
                setBooks(books);
            }
        }
        setDeleteOpen({open: false, book: {}})
    }

    const deleteBook = (book) => {
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken()}`);
        fetch(`${SERVER}/books/delete/${book.id}`, {
            headers: headers,
            method: "DELETE",
        }).catch(e => {
            console.log("Failed to delete book", e)
        });
    }

    return (
        <div className={classes.root}>
            {loading ? <CircularProgress size={100} color={'secondary'}/> :
                books.length > 0 ?
                    <GridList cellHeight={250} className={classes.gridList}>
                        {books.map((tile) => (
                            <GridListTile key={tile.id}>
                                <Card className={classes.card}
                                      onClick={() => window.open(tile.url, "_blank", "noopener noreferrer")}>
                                    <CardActionArea>
                                        <CardMedia
                                            className={classes.media}
                                            image={tile.thumb}
                                            title={tile.name}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2"
                                                        className={classes.heading}>
                                                {tile.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p"
                                                        className={classes.subtitle}>
                                                {new Date(tile.time).toLocaleString()}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <Button size="small" color="secondary"
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setDeleteOpen({open: true, book: tile});
                                                }}>
                                            {t('deleteBook')}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </GridListTile>
                        ))}
                    </GridList> :
                    <ComingSoon title={t(test ? 'no_tests' : "no_books")} bgColor="#fff" textColor="#212121"
                                illustration="git"/>
            }
            {!loading && books.length < 5 &&
            <Fab color={'secondary'} className={classes.fab} onClick={() => onAdd(test)}>
                <AddIcon/>
            </Fab>
            }
            <DeleteDialog onClose={handleDeleteClose}
                          test={test}
                          open={deleteOpen.open}/>
        </div>
    );
}

function DeleteDialog({open, onClose, test}) {
    const {t} = useTranslation();
    return <Dialog
        open={open}
        onClose={() => onClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {t(test ? 'do_you_want_to_delete_this_test' : 'do_you_want_to_delete_this_book')}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => onClose(false)} color="secondary">
                {t('no')}
            </Button>
            <Button onClick={() => onClose(true)} color="secondary" autoFocus>
                {t('yes')}
            </Button>
        </DialogActions>
    </Dialog>
}
import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        maxWidth: 700,
        textAlign: "center",
        margin: "0 auto",
    },
    logo: {
        maxWidth: 700,
        height: 300,
        width: '100%',
        objectFit: "contain",
    },
}));
export default function PositiveMessageView({message}) {
    const classes = useStyle();

    return (<div className={classes.root}>
            <img src={message.image} alt={message.author}
                 className={classes.logo}/>
            <br/>
            <br/>
            <br/>
            <br/>
            <Typography component={'h2'} variant={'h5'}>
                {message.text}
            </Typography>
            <br/>
            <br/>
            <Typography variant={'body1'}>
                {message.author}
            </Typography>
        </div>
    )
}
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, TextField, Typography} from '@material-ui/core';
import LogoView from '../common/view/LogoView';
import {makeStyles} from '@material-ui/core/styles';
import {SERVER} from "../index";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(2),
    },
    formDiv: {
        maxWidth: 500,
        textAlign: 'center',
        margin: '0 auto',
        padding: theme.spacing(2),
    },
    picker: {
        display: 'none',
    },
    result: {
        fontWeight: 'bold',
        fontSize: 'larger',
    }
}));

export default function DeleteAccount() {
    const classes = useStyles();
    const {t} = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState({severity: '', text: ''});

    const handleDelete = () => {
        if (!username || !password) {
            setMessage({severity: 'error', text: t('Please fill all fields')});
            return;
        }
        // make form-encoded data
        const body = []
        body.push(encodeURIComponent('username') + "=" + encodeURIComponent(username));
        body.push(encodeURIComponent('password') + "=" + encodeURIComponent(password));
        const formData = body.join("&");
        fetch(`${SERVER}/users/delete`, {
            method: "POST",
            body: formData,
            headers: {'Content-Type': 'application/x-www-form-urlencoded',}
        })
            .then(response => response.status === 200 ? Promise.resolve(response) : Promise.reject(new Error(response.statusText)))
            .then(() => {
                setMessage({severity: 'success', text: t('Account deleted successfully')});
            }).catch(e => {
            setMessage({
                severity: 'error',
                text: t('Error deleting account. Please check your credentials and try again')
            });
        })
    };

    return (
        <div className={classes.formDiv}>
            <LogoView/>
            <Typography variant="h4" gutterBottom>
                {t('Psychologist Assistant Account Deletion')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {t('Please enter your username and password to delete your account. We will confirm your request and will let you know once your account is deleted. Thank you for using our service.')}
            </Typography>
            <TextField
                label={t('Username')}
                value={username}
                color={'secondary'}
                onChange={(e) => setUsername(e.target.value)}
                margin="normal"
                variant="filled"
                fullWidth
            />
            <TextField
                label={t('Password')}
                value={password}
                color={'secondary'}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                variant="filled"
                fullWidth
            />
            <Alert severity={message.severity}>{message.text}</Alert>
            <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={handleDelete}
            >
                {t('Delete Account')}
            </Button>
            <div>
                <Typography variant="caption" gutterBottom>
                    {t('© 2024 Psychologist Assistant. All rights reserved.')}
                </Typography>
            </div>
        </div>
    );
}

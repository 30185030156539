import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getCountries} from "../../util/CountriesProvider";
import {useTranslation} from "react-i18next";

export default function CountryPickerView({onChange, errorCountry, errorCity, disabled}) {
    const [country, setCountry] = useState({});
    const [city, setCity] = useState("");

    const {t} = useTranslation();
    const handleChange = (event, newValue) => {
        if (newValue === null) {
            newValue = {};
            informChange(newValue, "");
        }
        if (newValue !== country) {
            setCity("");
            informChange(newValue, "");
        } else {
            informChange(newValue, city);
        }
        setCountry(newValue);

    }

    function informChange(country, city) {
        if (onChange !== null) {
            onChange(country, city);
        }
    }

    const handleCityChange = (event, newValue) => {
        setCity(newValue);
        informChange(country, newValue)
    }
    return <div>
        <Autocomplete
            id="country-picker-country"
            options={getCountries()}
            style={{marginTop: '1em', marginBottom: '1em'}}
            onChange={handleChange}
            value={country}
            disabled={disabled}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField
                color={'secondary'}
                error={errorCountry}
                helperText={errorCountry}
                {...params} label={t("select_country")} variant="standard"/>}
        />
        <Autocomplete
            id="country-picker-city"
            options={country.cities !== undefined ? country.cities : []}
            onChange={handleCityChange}
            value={city}
            style={{marginTop: '1em', marginBottom: '1em'}}
            disabled={country.name === undefined || disabled}
            renderInput={(params) => <TextField
                color={'secondary'}
                error={errorCity}
                helperText={errorCity}
                {...params} label={t("select_city")} variant="standard"/>}
        />
    </div>
}
export default {
    app_name: "مساعد الأخصائي النفسي",
    en: "الإنجليزية",
    ar: "العربية",
    about_us: "حول التطبيق",
    logout: "تسجيل خروج",
    courses: 'دورات',
    calendar_events: "التقويم",
    home_age: "حساب العمر",
    home_timer: "ساعة التوقف",
    events: "مناسبات",
    appointments: "مواعيد",
    todo: "مهام للعمل",
    convert_date: "تحويل التاريخ",
    book_library: "مكتبتي",
    relaxing_music: "صوتيات استرخاء",
    distribution_curve: "منحنى التوزيع الاعتدالي",
    positive_message: "رسائل إيجابية",
    news: "أخبار",
    psychological_test: "مقاييسي النفسية",
    youtube_news: "فيديو",
    pdf: "متنوعات نفسية",
    places: "مواقع هامة",
    notifications: "الإشعارات",
    contact_us: "الدعم الفني",
    login: "دخول",
    phone_number_or_email: "رقم الهاتف أو البريد الإلكتروني",
    password: "كلمة المرور",
    cancel: "إلغاء",
    login_message: "الرجاء إدخال اسم المستخدم وكلمة المرور لتسجيل الدخول. إذا لم يكن لديك حساب ، يمكنك التسجيل.",
    error_enter_password: "الرجاء إدخال كلمة المرور",
    error_enter_username: "يرجى إدخال اسم المستخدم",
    invalid_login: "كلمة المرور او اسم المستخدم غير صحيح",
    logout_message: "هل أنت متأكد أنك تريد الخروج",
    signup: "تسجيل",
    first_name: "اسمك",
    phone_number: "رقم الجوال",
    email: "البريد الالكتروني",
    confirm_password: "تاكيد كلمة المرور",
    select_country: "حدد الدولة",
    select_city: "حدد المدينة",
    signup_message: "الرجاء استخدام رقم الهاتف الصحيح وعنوان البريد الإلكتروني للتسجيل",
    error_input_name: "الرجاء إدخال اسمك",
    error_input_confirm_password: "تأكيد كلمة المرور غير متطابقة",
    error_input_phone: "الرجاء إدخال رقم الجوال",
    error_input_email: "الرجاء إدخال بريدك الالكتروني",
    error_select_country: " الرجاء اختيار الدولة",
    error_select_city: "الرجاء اختيار المدينة",
    unknown_error: "خطأ غير معروف",
    free_course: "مجانا",
    preview: "مشاهدة أولية",
    day: "اليوم",
    select_year: "حدد السنة",
    select_month: "اختر الشهر",
    error_birth_year: "فضلاً حدد سنة الميلاد",
    error_birth_day: "فضلاً أدخل شهر الميلاد",
    error_birth_month: "فضلاً حدد شهر الميلاد",
    invalid_birth_date: "تاريخ الميلاد غير صحيح",
    january: "يناير",
    february: "فبراير",
    march: "مارس",
    april: "أبريل",
    may: "مايو",
    june: "يونيو",
    july: "يوليو",
    august: "أغسطس",
    september: "سبتمبر",
    october: "أكتوبر",
    november: "نوفمبر",
    december: "ديسمبر",
    lap: "Lap",
    event_name: "اسم المناسبة",
    event_description: "وصف المناسبة(اختياري)",
    select_date_of_event: "اختر تاريخ المناسبة",
    select_time_of_event: "اختر وقت المناسبة",
    error_event_name: "فضلاً أدخل اسم المناسبة",
    unable_add_event: "غير ممكن إضافة المناسبة .فضلاً التأكد من اتصالك بالانترنت",
    event_added_success: "تم إضافة المناسبة بنجاح",
    add_event: "إضافة مناسبة",
    show_events: "عرض المناسبات",
    add_appointment_success: "تم الإرسال بنجاح",
    add_appointment: "أضف موعد",
    error_patient_name: "فضلاً أدخل اسم الحالة",
    error_select_date: "فضلاً حدد تاريخ الموعد",
    error_appointment_note: "فضلاً أدخل ملاحظات الموعد",
    patient_name: "الاسم",
    number: "رقم",
    select_date: "حدد تاريخ",
    select_time: "حدد الوقت",
    appointment_description: "تفاصيل الموعد",
    show_appointments: "عرض المواعيد",
    select_hijri_date: "تحويل التاريخ الهجري",
    select_gregorian_date: "تحويل التاريخ الميلادي",
    upload_book: "تحميل كتاب",
    book_name: "اسم الكتاب",
    note_upload_file: "فقط ملفات البي دي اف التي يمكن تحميلها",
    error_book_file: "فضلاً حدد ملف الكتاب",
    success_book_uploaded: "تم تحميل الكتاب بنجاح",
    error_book_name: "فضلاً أدخل اسم الكتاب",
    select_book_file: "حدد ملف الكتاب",
    error_book_upload: "غير ممكن تحميل الكتاب .فضلاً حاول مر أخرى",
    upload_psy_test: "ارفع المقياس",
    psy_test_name: "اسم المقياس",
    select_psy_test_file: "اختار الملف",
    error_psy_test_upload: "غير قادر على اضافة المقياس الرجاء المحاولة مره اخرى يجب ان يكون الملف اقل من 10MB",
    error_psy_test_name: "الرجاء كتابة اسم المقياس",
    success_psy_test_uploaded: "تم رفع المقياس بنجاح",
    your_feedback: "اكتب ملاحظاتك هنا!",
    send_feedback: "إرسال",
    thanks_feedback: "شكرا لتوفير التغذية الراجعة.",
    message_write_feedback: "يرجى كتابة ملاحظاتك.",
    description_about_us: "تطبيق مساعد الأخصائي النفسي هو تطبيق يهدف إلى مساعدة الأخصائي النفسي في تنظيم عمله من خلال توفير مجموعة من الأدوات التي يحتاجها و جمعها في مكان واحد",
    application_by: "فكرة التطبيق",
    owner_name: "ريم عبدالرزاق",
    owner_profession: "أخصائية نفسية",
    developed_by: "برمجة وتطوير 💖 مسارات التكنولوجيا http://massarttech-apps.com",
    ok: "موافق",
    open_pdf: "افتح ملف البي دي اف",
    close: "أغلق",
    already_registered: "تم تسجيل البريد بالفعل",
    age_format_string: "1 سنة 2 شهر 3 أيام",
    no: "لا (إلغاء)",
    yes: "نعم ، (حذف)",
    do_you_want_to_delete_this_book: "هل تريد حذف هذا الكتاب؟",
    deleteBook: "حذف",
    do_you_want_to_delete_this_test: 'هل تريد حذف هذا الاختبار؟',
    no_books: "لم تقم  بتحميل أي كتاب",
    no_tests: "لم تقم  بتحميل أي مقاييس النفسية",
    forgot_pass: "نسيت كلمة المرور",
    error_reset_password: "لم يتم العثور على حساب للبريد الإلكتروني المدخل.",
    resend_code_reset: "تم إرسال الكود الى بريدك الالكتروني ",
    password_updated: "تم تحديث كلمة المرور",
    dialog_message: "رسالة",
    error_invalid_opt: "كود التأكيد خاطئ ",
    account_verified: "تم التأكد من الحساب",
    message_code_resend: "تم إرسال الكو لجوالك",
    reset_password: "استعادة كلمة المرور",
    new_password: "كلمة مرور جديدة",
    confirm_new_password: "تأكيد كلمة المرور الجديدة",
    save: "حفظ",
    enter_otp: "أدخل كود التأكيد",
}
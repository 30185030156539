import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import {accessToken, getUser} from "../util/UserPrefs";
import {useMountEffect} from "../util/Global";
import {Divider} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SERVER} from "../index";

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        maxWidth: '150ch',
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },

}));

export default function EventsListView({date, appointment, event}) {
    const classes = useStyles();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = getUser();

    useMountEffect(() => {
        if (user == null) {
            return;
        }
        setLoading(true);
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken()}`);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER}/events/user/${user.id}`, {
            headers: headers,
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setLoading(false);
                if (Array.isArray(data)) {
                    if (date !== null && date !== undefined) {
                        data = data.filter(event => (new Date(event.dateOfEvaluation).toLocaleDateString() === date))
                    }
                    if (appointment) {
                        data = data.filter(event => event.phone !== null);
                    } else if (event) {
                        data = data.filter(event => event.phone === null);
                    }
                    setEvents(data);
                }
            })
            .catch(e => {
                if (!aborted) {
                    setLoading(false);
                }
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    })

    return (
        <div className={classes.root}>
            {loading ? <CircularProgress size={100} color={'secondary'}/> :
                <List className={classes.list}>
                    {events.map(event => (
                        <div key={event.id}>
                            <ListItem alignItems="flex-start"
                                      key={event.id} button>
                                <ListItemText
                                    primary={<React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="h5"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {event.name}
                                        </Typography>

                                    </React.Fragment>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {event.phone !== null && <span>{event.phone}<br/></span>}
                                                <span>{event.comments}<br/></span>
                                            </Typography>
                                            {new Date(event.dateOfEvaluation).toLocaleString()}
                                        </React.Fragment>
                                    }/>

                            </ListItem>
                            <Divider component="li"/>
                        </div>
                    ))}
                </List>
            }
        </div>
    );
}
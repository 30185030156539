import React, {useState} from 'react';
import {Button, Dialog, DialogActions, FormControl, Input} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {SERVER} from "../index";
import {saveUser} from "../util/UserPrefs";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import CountryPickerView from "../common/view/CountryPickerView";
import {validateEmail} from "../util/Global";

export default function SignUpDialog({open, onResponse}) {
    const {t} = useTranslation();
    const [error, setError] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [name, setName] = useState("");
    const [errorUsername, setErrorUsername] = useState(null);
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [errorPassword, setErrorPassword] = useState(null);
    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [country, setCountry] = useState({});
    const [countryError, setCountryError] = useState(null);
    const [city, setCity] = useState("");
    const [cityError, setCityError] = useState(null);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {

        }
    };
    const handleChange = name => (event) => {
        if (name === 'password') {
            let val = event.target.value;
            setPassword(val);
            setErrorPassword(null);
        } else if (name === 'username') {
            let val = event.target.value;
            setUsername(val);
            setErrorUsername(null);
        } else if (name === 'name') {
            let val = event.target.value;
            setName(val);
            setErrorName(null);
        } else if (name === "email") {
            setEmail(event.target.value);
            setErrorEmail(null);
        } else if (name === "confirmPassword") {
            setConfirmPassword(event.target.value);
            setErrorConfirmPassword(event.target.value !== password)
        }
    };
    const handleLogin = () => {
        if (name.length < 3) {
            setErrorName(t('error_input_name'));
            return;
        }
        if (username.length < 6) {
            setErrorUsername(t('error_input_phone'))
            return;
        }
        if (!validateEmail(email)) {
            setErrorEmail(t('error_input_email'));
            return;
        }
        if (password.length < 6) {
            setErrorPassword(t('error_enter_password'))
            return;
        }

        if (confirmPassword !== password) {
            setErrorConfirmPassword(t('error_input_confirm_password'));
            return;
        }
        if (country === undefined || country.length < 1) {
            setCountryError(t('error_select_country'));
            return;
        }
        if (city == null || city.length < 1) {
            setCityError(t('error_select_city'));
            return;
        }
        signUp();
    }

    const signUp = () => {
        const user = {
            name: name,
            enabled: false,
            password: password,
            authority: {
                name: "ROLE_USER",
                id: 1
            },
            email: email,
            country: country.name,
            city: city,
            username: username,
            platform: "web",
        };
        setLoading(true);
        setError(null);
        fetch(`${SERVER}/users/insert`, {
            method: "POST",
            body: JSON.stringify(user),
            headers: {'Content-Type': 'application/json'}
        })
            .then(response => response.json())
            .then(user => {
                setLoading(false)
                setName("");
                setPassword("");
                setUsername("");
                setEmail("");
                setConfirmPassword("");
                saveUser(user)
                onResponse(true)
            }).catch(e => {
            setLoading(false)
            setError(t('already_registered'))
        })
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
            <DialogTitle
                id="form-dialog-title">{t('signup')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("signup_message")}
                </DialogContentText>
                <TextField
                    fullWidth
                    error={errorName}
                    style={{marginTop: '1em', marginBottom: '1em'}}
                    id={"name"}
                    label={t("first_name")}
                    value={name}
                    color={"secondary"}
                    disabled={loading}
                    helperText={errorName}
                    onChange={handleChange('name')}
                    required/>
                <TextField
                    fullWidth
                    error={errorUsername}
                    helperText={errorUsername}
                    style={{marginTop: '1em', marginBottom: '1em'}}
                    id={"username"}
                    type={'tel'}
                    label={t("phone_number")}
                    value={username}
                    color={"secondary"}
                    disabled={loading}
                    onChange={handleChange('username')}
                    required/>
                <TextField
                    fullWidth
                    error={errorEmail}
                    style={{marginTop: '1em', marginBottom: '1em'}}
                    id={"email"}
                    label={t("email")}
                    value={email}
                    type={'email'}
                    color={"secondary"}
                    helperText={errorEmail}
                    disabled={loading}
                    onChange={handleChange('email')}
                    required/>
                <FormControl style={{marginTop: '1em', marginBottom: '1em'}} fullWidth required>
                    <InputLabel>{t('password')}</InputLabel>
                    <Input
                        id={"password"}
                        color={'secondary'}
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        error={errorPassword}
                        disabled={loading}
                        onChange={handleChange('password')}/>
                    <FormHelperText id="error-helper-text">{errorPassword}</FormHelperText>
                </FormControl>
                <TextField
                    fullWidth
                    helperText={errorConfirmPassword}
                    error={errorConfirmPassword}
                    style={{marginTop: '1em', marginBottom: '1em'}}
                    id={"confirmPassword"}
                    label={t("confirm_password")}
                    value={confirmPassword}
                    type={'password'}
                    color={"secondary"}
                    disabled={loading}
                    onChange={handleChange('confirmPassword')}
                    required/>
                <CountryPickerView
                    errorCity={cityError}
                    errorCountry={countryError}
                    disabled={loading}
                    onChange={(country, city) => {
                        setCountry(country.name);
                        setCountryError(country.name === undefined ? t('error_select_country') : null);
                        setCity(city);
                        setCityError(city.length > 1 ? null : t('error_select_city'));
                    }}/>
                <span style={{color: 'red'}}>{error}</span>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading}
                        variant={'text'} color={'secondary'}
                        onClick={() => {
                            onResponse(null, false);
                        }}>
                    {t("cancel")}
                </Button>
                <Button disabled={loading} variant={'text'}
                        color={'secondary'}
                        onClick={handleLogin}>
                    {loading && (
                        <CircularProgress color={"secondary"} size={25} thickness={2}/>
                    )}
                    {t("signup")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
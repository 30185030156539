import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import {getUser} from "../util/UserPrefs";
import {useMountEffect} from "../util/Global";
import {Divider} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SERVER} from "../index";

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        maxWidth: '150ch',
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },

}));

export default function EventsListView() {
    const classes = useStyles();
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const user = getUser();

    useMountEffect(() => {
        if (user == null) {
            return;
        }
        setLoading(true);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER}/messages/get?unpaged=true`, {
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(({content}) => {
                setLoading(false);
                if (Array.isArray(content)) {
                    setNotifications(content);
                }
            })
            .catch(e => {
                if (!aborted) {
                    setLoading(false);
                }
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    })

    return (
        <div className={classes.root}>
            {loading ? <CircularProgress size={100} color={'secondary'}/> :
                <List className={classes.list}>
                    {notifications.map(notification => (
                        <div key={notification.id}>
                            <ListItem alignItems="flex-start"
                                      key={notification.id} button>
                                <ListItemText
                                    primary={<React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="h5"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {notification.subject}
                                        </Typography>

                                    </React.Fragment>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {notification.body}
                                            </Typography>
                                            - {new Date(notification.time).toLocaleString()}
                                        </React.Fragment>
                                    }/>

                            </ListItem>
                            <Divider component="li"/>
                        </div>
                    ))}
                </List>
            }
        </div>
    );
}
import React from "react";
import {Button, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import ReactPlayer from "react-player/youtube";
import {useTranslation} from "react-i18next";
import MapView from "../../common/view/MapView";

const useStyle = makeStyles(theme => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        maxWidth: 700,
        textAlign: "center",
        margin: "0 auto",
    },
    logo: {
        maxWidth: 700,
        height: 300,
        width: '100%',
        objectFit: "contain",
    },
    map: {
        width: 700,
        height: 300,
    },
    button: {
        color: '#FFF',
    }
}));
export default function NewsDetailView({news, video, pdf, place}) {
    const classes = useStyle();
    const {t} = useTranslation();
    const openPdf = () => {
        window.open(news.pdf, "_blank", "noopener noreferrer")
    }
    return (<div className={classes.root}>
            {video && <ReactPlayer
                controls
                url={news.link}/>}
            {place && <MapView lat={news.location.latitude}
                               lng={news.location.longitude}
                               title={news.title}
                               className={classes.map}
            />}
            {!place && !video && <img src={news.image} alt={news.title}
                                      className={classes.logo}/>}
            <br/>
            <br/>
            <br/>
            <br/>
            <Typography component={'h2'} variant={'h5'}>
                {news.title}
            </Typography>
            <br/>
            <div dangerouslySetInnerHTML={{__html: news.text}}/>
            {place && <div>
                <br/>
                <br/>
                <Typography component={'p'}
                            variant={'body2'}>
                    {news.phone}
                </Typography>
                {news.website !== null && news.website.startsWith("http") && <div><br/>
                    <br/>
                    <a href={news.website} target={"_black"} rel={'noopener norefferer'}>{news.website}</a>
                </div>}
            </div>}

            {pdf && <div>
                <br/>
                <br/>
                <Button color={'secondary'}
                        variant={'contained'}
                        className={classes.button}
                        onClick={openPdf}>{t('open_pdf')}</Button></div>}

            <br/>
            <br/>
            <Typography color={'textSecondary'}
                        component={'p'}
                        variant={'body2'}>
                {new Date(news.time).toLocaleString()}
            </Typography>
        </div>
    )
}
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import LogoView from "../../common/view/LogoView";
import TextField from "@material-ui/core/TextField";
import {Button, DialogContentText, Typography} from "@material-ui/core";
import MomentUtils from '@date-io/moment';
import materialTheme from '../../common/pickerTheme';
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {ThemeProvider} from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {SERVER} from "../../index";
import {accessToken, getUser} from "../../util/UserPrefs";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    formDiv: {
        maxWidth: 500,
        textAlign: "center",
        margin: "0 auto",
    },
    field: {
        margin: theme.spacing(2),
    },
    submitButton: {
        margin: theme.spacing(2),
        maxWidth: 300,
        color: '#FFF',
    }
}));
export default function AddAppointmentView({onDone, onShowEvents}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(new Date(new Date().getTime() + 60 * 60 * 1000)); //add one hour to current time
    const [description, setDescription] = useState("");
    const [result, setResult] = useState({text: "", error: false, loading: false});
    const [dialog, setDialog] = useState({open: false, message: ""});

    const onChange = name => event => {
        if (name === "name") {
            setName(event.target.value);
        } else if (name === "date") {
            let d = event.toDate();
            setDate(d);
            let t = new Date(d.getTime());
            t.setHours(time.getHours());
            let min = new Date().getTime() + 60 * 60 * 1000;
            if (t.getTime() < min) {
                //set minimum time to one hour from now
                setTime(min);
                return;
            }
            setTime(t);
        } else if (name === "time") {
            let t = event.toDate();
            let min = new Date().getTime() + 60 * 60 * 1000;
            if (t.getTime() < min) {
                return;
            }
            setTime(event.toDate());
        } else if (name === "description") {
            setDescription(event.target.value);
        } else if (name === "number") {
            setPhone(event.target.value);
        }
    };
    const onSave = () => {
        if (name.length < 1) {
            setResult({text: t('error_event_name'), error: true, loading: false});
            return;
        }
        if (description.length < 1) {
            setResult({text: t('error_appointment_note'), error: true, loading: false});
            return;
        }
        addAppointment();
    }

    const addAppointment = () => {
        const user = getUser();

        const event = {
            name: name,
            comments: description,
            userId: user.id,
            time: new Date().getTime(),
            dob: date.getTime(),
            dateOfEvaluation: time.getTime(),
            phone: phone,
            image: "",
        };
        setResult({text: "", error: false, loading: true});

        fetch(`${SERVER}/events/insert`, {
            method: "POST",
            body: JSON.stringify(event),
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken()}`}
        })
            .then(response => response.json())
            .then(() => {
                setResult({loading: false, error: false, text: ""});
                setDialog({open: true, message: t('add_appointment_success')});
            }).catch(e => {
            setDialog({open: true, message: t('unable_add_event')});
            setResult({loading: false, error: false, text: ""});
        })
    }

    const onDialogClose = () => {
        setDialog({open: false, message: ""});
        onShowEvents();
    };

    return <div className={classes.root}>
        <LogoView title={t('appointments')}/>
        <div className={classes.formDiv}>
            <TextField variant={'filled'}
                       className={classes.field}
                       color={'secondary'}
                       value={name}
                       required
                       disabled={result.loading}
                       fullWidth
                       label={t('patient_name')}
                       onChange={onChange("name")}
                       name={"name"}/>
            <TextField variant={'filled'}
                       className={classes.field}
                       color={'secondary'}
                       value={phone}
                       disabled={result.loading}
                       fullWidth
                       label={t('number')}
                       onChange={onChange("number")}
                       name={"number"}/>
            <ThemeProvider theme={materialTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                        className={classes.field}
                        value={date}
                        required
                        okLabel={t('ok')}
                        cancelLabel={t('cancel')}
                        disabled={result.loading}
                        inputVariant={'filled'}
                        minDate={new Date()}
                        fullWidth
                        format={"DD-MM-YYYY"}
                        label={t('select_date')}
                        onChange={onChange("date")}
                        name={"date"}/>
                    <KeyboardTimePicker
                        className={classes.field}
                        value={time}
                        required
                        okLabel={t('ok')}
                        cancelLabel={t('cancel')}
                        disabled={result.loading}
                        inputVariant={'filled'}
                        minDate={new Date(new Date().getTime() + (60 * 60 * 1000))}
                        format={"hh:mm:ss"}
                        fullWidth
                        label={t('select_time')}
                        onChange={onChange("time")}
                        name={"time"}/>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
            <TextField variant={'filled'}
                       className={classes.field}
                       color={'secondary'}
                       value={description}
                       multiline
                       required
                       rows={3}
                       fullWidth
                       disabled={result.loading}
                       label={t('appointment_description')}
                       onChange={onChange("description")}
                       name={"description"}/>

            <Typography variant={'body1'} color={result.error ? "error" : "textPrimary"}>{result.text}</Typography>
            <Button
                fullWidth
                onClick={onSave}
                disabled={result.loading}
                className={classes.submitButton}
                color={'secondary'} variant={'contained'}>
                {result.loading && <CircularProgress color={'secondary'}/>}{t('add_appointment')}
            </Button>
            <div>
                <Button
                    onClick={() => onShowEvents()}
                    disabled={result.loading}
                    color={'secondary'} variant={'text'}>{t('show_appointments')}</Button>
            </div>
        </div>
        <Dialog open={dialog.open}
                onClose={onDialogClose}>
            <DialogContent>
                <DialogContentText id={'response-dialog-text'}>
                    {dialog.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={onDialogClose}>
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    </div>

}

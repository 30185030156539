import React from 'react';
import './App.css';
import {AppTheme} from "./theme";
import {jssPreset, StylesProvider, ThemeProvider} from '@material-ui/core/styles';
import ActionBar from "./layout/ActionBar";
import {create} from 'jss';
import rtl from 'jss-rtl';
import DeleteAccount from "./controller/delete-account";

function App() {
    let direction = localStorage.getItem('dir');
    if (direction === null) {
        direction = 'ltr';
    }
    document.dir = direction;
    console.log(window.location.search)
    const params = new URLSearchParams(window.location.search);
    const hasDelete = params.get('delete');
    const jss = create({plugins: [...jssPreset().plugins, rtl()]});

    const app = <div dir={direction}>
        <ThemeProvider theme={AppTheme}>
            {hasDelete ? <DeleteAccount/> : <ActionBar/>}
        </ThemeProvider>
    </div>;


    if (direction === 'rtl') {
        return <StylesProvider jss={jss}>
            {app}
        </StylesProvider>
    }
    return app;

}

export default App;

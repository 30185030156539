import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import {useMountEffect} from "../util/Global";
import {Divider} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SERVER} from "../index";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        maxWidth: '150ch',
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },

}));

export default function PositiveMessagesListView({onClick}) {
    const classes = useStyles();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    useMountEffect(() => {
        setLoading(true);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER}/quotes/get?size=10000`, {
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setLoading(false);
                if (Array.isArray(data.content)) {
                    setMessages(data.content);
                }
            })
            .catch(e => {
                if (!aborted) {
                    setLoading(false);
                }
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    })

    return (
        <div className={classes.root}>
            {loading ? <CircularProgress size={100} color={'secondary'}/> :
                <List className={classes.list}>
                    {messages.map(quote => (
                        <div key={quote.id}>
                            <ListItem alignItems="flex-start"
                                      onClick={() => onClick(quote)}
                                      key={quote.id} button>
                                <ListItemAvatar>
                                    <Avatar alt={quote.author}
                                            src={quote.image}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="body1"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {quote.text}
                                        </Typography>

                                    </React.Fragment>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {quote.author}
                                            </Typography>
                                        </React.Fragment>
                                    }/>

                            </ListItem>
                            <Divider component="li"/>
                        </div>
                    ))}
                </List>
            }
        </div>
    );
}
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {Button, DialogContentText, FormControl, Input, Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LogoView from "../../common/view/LogoView";
import {SERVER} from "../../index";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    formDiv: {
        maxWidth: 500,
        textAlign: "center",
        margin: "0 auto",
    },
    field: {
        margin: theme.spacing(2),
    },
    submitButton: {
        margin: theme.spacing(2),
        maxWidth: 300,
        color: '#FFF',
    }
}));
export default function ResetPasswordView({email, onDone}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [code, setCode] = useState("");
    const [result, setResult] = useState({text: "", error: false, loading: false});
    const [dialog, setDialog] = useState({open: false, message: ""});

    const onChange = name => event => {
        if (name === "password") {
            setPassword(event.target.value);
        } else if (name === "code") {
            setCode(event.target.value);
        } else if (name === "confirmPassword") {
            setConfirmPassword(event.target.value);
        }
    };
    const onSave = () => {
        if (password.length < 6) {
            setResult({text: t('error_enter_password'), error: true, loading: false});
            return;
        }
        if (confirmPassword !== password) {
            setResult({text: t('error_input_confirm_password'), error: true, loading: false});
            return;
        }
        if (code.length !== 4) {
            setResult({text: t('error_invalid_opt'), error: true, loading: false});
            return;
        }
        updatePassword();
    }

    const updatePassword = () => {
        const body = {
            password: password,
            phone: email,
            verificationCode: code,
        };
        setResult({text: "", error: false, loading: true});
        const paramsArray = [];
        for (let prop in body) {
            let encodedKey = encodeURIComponent(prop);
            let encodedValue = encodeURIComponent(body[prop]);
            paramsArray.push(encodedKey + "=" + encodedValue);
        }
        const params = paramsArray.join("&");
        fetch(`${SERVER}/users/updatePassword`, {
            method: "POST",
            body: params,
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                }
                return Promise.reject(response.status)
            })
            .then(() => {
                setResult({loading: false, error: false, text: ""});
                setDialog({open: true, message: t('password_updated')});
            }).catch(e => {
            setResult({loading: false, error: true, text: t('error_invalid_opt')});
        })
    }

    const onDialogClose = () => {
        setDialog({open: false, message: ""});
        onDone()
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return <div className={classes.root}>
        <LogoView title={t('reset_password')}/>
        <div className={classes.formDiv}>
            <TextField
                fullWidth
                style={{marginTop: '1em', marginBottom: '1em'}}
                id={"code"}
                label={t("enter_otp")}
                value={code}
                type={'number'}
                color={"secondary"}
                disabled={result.loading}
                onChange={onChange('code')}
                required/>
            <FormControl style={{marginTop: '1em', marginBottom: '1em'}} fullWidth required>
                <InputLabel>{t('new_password')}</InputLabel>
                <Input
                    id={"password"}
                    color={'secondary'}
                    value={password}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <Visibility/> : <VisibilityOff/>}
                            </IconButton>
                        </InputAdornment>
                    }
                    disabled={result.loading}
                    onChange={onChange('password')}/>
            </FormControl>
            <TextField
                fullWidth
                style={{marginTop: '1em', marginBottom: '1em'}}
                id={"confirmPassword"}
                label={t("confirm_new_password")}
                value={confirmPassword}
                type={'password'}
                color={"secondary"}
                disabled={result.loading}
                onChange={onChange('confirmPassword')}
                required/>

            <Typography variant={'body1'} color={result.error ? "error" : "textPrimary"}>{result.text}</Typography>
            <Button
                fullWidth
                onClick={onSave}
                disabled={result.loading}
                className={classes.submitButton}
                color={'secondary'} variant={'contained'}>
                {result.loading && <CircularProgress color={'secondary'}/>}{t('reset_password')}
            </Button>
        </div>
        <Dialog open={dialog.open}
                onClose={onDialogClose}>
            <DialogContent>
                <DialogContentText id={'response-dialog-text'}>
                    {dialog.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={onDialogClose}>
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    </div>

}

import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {accessToken, getUser} from "../../util/UserPrefs";
import {SERVER} from "../../index";
import {useMountEffect} from "../../util/Global";
import {Divider} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactPlayer from "react-player";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import PlayingIcon from '@material-ui/icons/Lens';

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        maxWidth: '150ch',
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    data: {
        maxWidth: 600,
        textAlign: "center",
        margin: "0 auto",
    }

}));

export default function CourseDetailView({course}) {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [playing, setPlaying] = useState(null);
    const user = getUser();

    useMountEffect(() => {
        if (user == null) {
            return;
        }
        setLoading(true);
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken()}`);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER}/courseItems/getOfCourse/${course.id}`, {
            headers: headers,
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setLoading(false);
                if (Array.isArray(data)) {
                    setItems(data);
                }
            })
            .catch(e => {
                if (!aborted) {
                    setLoading(false);
                }
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    })

    const onItemClick = item => {
        setPlaying(item);
    }
    return (
        <div className={classes.root}>
            {playing != null && <div className={classes.data}><ReactPlayer
                controls
                width={'100%'}
                url={`${playing.video}?auth=${accessToken()}`}/></div>}
            <div className={classes.data}>
                {loading ?
                    <CircularProgress size={100} color={'secondary'}/> :
                    <List className={classes.list}>
                        {items.map(course => (
                            <div key={course.id}>
                                <ListItem alignItems="flex-start"
                                          onClick={() => onItemClick(course)}
                                          key={course.id} button>
                                    <ListItemAvatar>
                                        <Avatar alt={course.title}
                                                src={course.thumb}/>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="h5"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {course.title}
                                            </Typography>

                                        </React.Fragment>}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    <div dangerouslySetInnerHTML={{
                                                        __html: course.content
                                                    }}/>
                                                </Typography>

                                            </React.Fragment>
                                        }/>
                                    {playing != null && playing.id === course.id &&
                                    <ListItemSecondaryAction color={'secondary'}>
                                        <IconButton edge="end" aria-label="playing" color={'secondary'}>
                                            <PlayingIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                    }
                                </ListItem>
                                <Divider variant="inset" component="li"/>
                            </div>
                        ))}
                    </List>
                }
            </div>
        </div>
    );
}

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import LogoView from "../../common/view/LogoView";
import TextField from "@material-ui/core/TextField";
import {Button, DialogContentText, FormControl, FormHelperText, Typography} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {SERVER} from "../../index";
import {accessToken, getUser} from "../../util/UserPrefs";
import FilledInput from "@material-ui/core/FilledInput";
import Dropzone from "react-dropzone";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    formDiv: {
        maxWidth: 500,
        textAlign: "center",
        margin: "0 auto",
    },
    field: {
        margin: theme.spacing(2),
    },
    submitButton: {
        margin: theme.spacing(2),
        maxWidth: 300,
        color: '#FFF',
    },
    result: {
        textAlign: 'center',
        margin: theme.spacing(1),
    }
}));
export default function AddBookView({onDone, test = false}) {
    const {t} = useTranslation();
    const classes = useStyles();
    const [name, setName] = useState("");
    const [file, setFile] = useState(null);
    const [result, setResult] = useState({text: "", error: false, loading: false});
    const [dialog, setDialog] = useState({open: false, message: ""});
    const onChange = name => event => {
        if (name === "name") {
            setName(event.target.value);
        } else if (name === "file") {
            setFile(event.target.value);
        }
    };
    const onSave = () => {
        if (name.length < 1) {
            setResult({text: t(test ? 'error_psy_test_name' : 'error_book_name'), error: true, loading: false});
            return;
        }
        if (file.length < 1) {
            setResult({text: t(test ? 'select_psy_test_file' : 'error_book_file'), error: true, loading: false});
            return;
        }
        uploadBook(book => addBook(book))
    }

    const uploadBook = (onDone) => {
        setResult({text: "", error: false, loading: true});
        let formData = new FormData();
        formData.append('file', file);
        let url = SERVER + "/upload/uploadFile";
        const token = accessToken();

        let requestHeaders = new Headers();
        requestHeaders.set('Authorization', `Bearer ${token}`);
        requestHeaders.append("Access-Control-Allow-Origin", "*");
        fetch(url, {headers: requestHeaders, body: formData, method: 'POST'})
            .then(response => response.text())
            .then((body) => {
                if (body === undefined) {
                    setResult({
                        text: t(test ? 'error_psy_test_upload' : 'error_book_upload'),
                        error: true,
                        loading: false
                    });
                    return;
                }
                onDone(body)
            }).catch(e => {
            console.log(e);
            setResult({text: t(test ? 'error_psy_test_upload' : 'error_book_upload'), error: true, loading: false});
        })
    }

    const addBook = (bookUrl) => {
        console.log(bookUrl);
        const user = getUser();
        const event = {
            name: name,
            userId: user.id,
            test: test,
            url: bookUrl,
            time: new Date().getTime(),
        };
        setResult({text: "", error: false, loading: true});

        fetch(`${SERVER}/books/insert`, {
            method: "POST",
            body: JSON.stringify(event),
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken()}`}
        })
            .then(response => response.json())
            .then(() => {
                setResult({loading: false, error: false, text: ""});
                setDialog({open: true, message: t(test ? 'success_psy_test_uploaded' : 'success_book_uploaded')});
            }).catch(e => {
            setDialog({open: true, message: t(test ? 'error_psy_test_upload' : 'error_book_upload')});
            setResult({loading: false, error: false, text: ""});
        })
    }

    const onDialogClose = () => {
        setDialog({open: false, message: ""});
        onDone()
    };

    return <div className={classes.root}>
        <LogoView title={t(test ? 'upload_psy_test' : 'upload_book')}/>
        <div className={classes.formDiv}>
            <TextField variant={'filled'}
                       className={classes.field}
                       color={'secondary'}
                       value={name}
                       required
                       disabled={result.loading}
                       fullWidth
                       label={t(test ? 'psy_test_name' : 'book_name')}
                       onChange={onChange("name")}
                       name={"name"}/>
            <Dropzone onDrop={acceptedFiles => setFile(acceptedFiles[0])}
                      multiple={false}
            >
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()}>
                            <FormControl className={classes.field} fullWidth>
                                <FilledInput type={'file'}
                                             onChange={getInputProps().onChange}
                                             inputProps={{accept: "application/pdf"}}/>
                                <FormHelperText>{t(test ? 'select_psy_test_file' : 'select_book_file')}</FormHelperText>
                            </FormControl>
                        </div>
                    </section>
                )}
            </Dropzone>

            <Typography variant={'body1'}
                        className={classes.result}
                        color={result.error ? "error" : "textPrimary"}>{result.text}</Typography>
            <Button
                fullWidth
                onClick={onSave}
                disabled={result.loading}
                className={classes.submitButton}
                color={'secondary'} variant={'contained'}>
                {result.loading && <CircularProgress color={'secondary'}/>}{t(test ? 'upload_psy_test' : 'upload_book')}
            </Button>
        </div>
        <Dialog open={dialog.open}
                onClose={onDialogClose}>
            <DialogContent>
                <DialogContentText id={'response-dialog-text'}>
                    {dialog.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={onDialogClose}>
                    {t('ok')}
                </Button>
            </DialogActions>
        </Dialog>
    </div>

}

import React from "react";
import "./stopwatch.css";
import {Fab} from "@material-ui/core";
import PlayIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import LapIcon from '@material-ui/icons/Flag';
import PauseIcon from '@material-ui/icons/Pause';

const formattedSeconds = (sec) =>
    Math.floor(sec / 60) +
    ':' +
    ('0' + sec % 60).slice(-2)


export default class Stopwatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            secondsElapsed: 0,
            laps: [],
            lastClearedIncrementer: null
        };
        this.incrementer = null;
    }

    handleStartClick() {
        this.incrementer = setInterval(() =>
                this.setState({
                    secondsElapsed: this.state.secondsElapsed + 1
                })
            , 1000);
    }

    handleStopClick() {
        clearInterval(this.incrementer);
        this.setState({
            lastClearedIncrementer: this.incrementer
        });
    }

    handleResetClick() {
        clearInterval(this.incrementer);
        this.setState({
            secondsElapsed: 0,
            laps: []
        });
    }

    handleLabClick() {
        this.setState({
            laps: this.state.laps.concat([this.state.secondsElapsed])
        })
    }

    render() {
        return (
            <div className="stopwatch">
                <h1 className="stopwatch-timer">{formattedSeconds(this.state.secondsElapsed)}</h1>

                {(this.state.secondsElapsed === 0 ||
                    this.incrementer === this.state.lastClearedIncrementer
                        ? <Fab onClick={this.handleStartClick.bind(this)}
                               style={{margin: '1em', color: '#FFF'}}
                               color={'secondary'}><PlayIcon/></Fab>
                        : <Fab onClick={this.handleStopClick.bind(this)}
                               style={{margin: '1em', color: '#FFF'}}
                               color={'secondary'}><PauseIcon/></Fab>
                )}

                {(this.state.secondsElapsed !== 0 &&
                    this.incrementer !== this.state.lastClearedIncrementer
                        ? <Fab onClick={this.handleLabClick.bind(this)}
                               style={{margin: '1em', color: '#FFF'}}
                               color={'secondary'}><LapIcon/></Fab>
                        : null
                )}


                {(this.state.secondsElapsed !== 0 &&
                    this.incrementer === this.state.lastClearedIncrementer
                        ? <Fab onClick={this.handleResetClick.bind(this)}
                               style={{margin: '1em', color: '#FFF'}}
                               color={'secondary'}><StopIcon/></Fab>
                        : null
                )}

                <ul className="stopwatch-laps">
                    {this.state.laps.map((lap, i) =>
                        <li className="stopwatch-lap" key={i}><strong>{i + 1}</strong>/ {formattedSeconds(lap)}</li>)
                    }
                </ul>
            </div>
        );
    }
}
import CourseDetailView from "../controller/course/CourseDetailView";
import EventsListView from "../controller/EventsListView";
import AddBookView from "../controller/books/AddBookView";
import NewsDetailView from "../controller/news/NewsDetailView";
import CourseListView from "../controller/course/CourseListView";
import EventsView from "../controller/calendar/EventsView";
import CalculateAgeView from "../controller/CalculateAgeView";
import Stopwatch from "../controller/stopwatch/Stopwatch";
import AddEventView from "../controller/event/AddEventView";
import AddAppointmentView from "../controller/appointment/AddAppointmentView";
import ConvertDateView from "../controller/ConvertDateView";
import BooksGridView from "../controller/books/BooksGridView";
import MusicListView from "../controller/MusicListView";
import PositiveMessagesListView from "../controller/PositiveMessagesListView";
import NewsGridView from "../controller/news/NewsGridView";
import ContactUs from "../controller/ContactUs";
import NotificationsListView from "../controller/NotificationsListView";
import AboutView from "../common/view/AboutView";
import React from "react";
import PositiveMessageView from "../controller/PositiveMessageView";
import Curve from "../controller/curve/Curve";
import EmailInputView from "../controller/forgot/EmailInputView";
import ResetPasswordView from "../controller/forgot/ResetPasswordView";

export default function CurrentView(item, t, onClick, onBack) {
    if (item.id === undefined) {
        item.id = item.title;
    }
    switch (item.id) {
        case "courseDetail":
            return <CourseDetailView course={item.data}/>
        case 'show_events':
            return <EventsListView date={item.date}
                                   event={item.event}
                                   appointment={item.appointment}/>
        case 'upload_book':
            return <AddBookView onDone={onBack} test={item.test}/>
        case 'news_detail':
            return <NewsDetailView news={item.data}
                                   pdf={item.pdf}
                                   place={item.place}
                                   video={item.video}/>
        case 'positive_message':
            return <PositiveMessageView message={item.data}/>
        case t('courses'):
            return <CourseListView
                onClick={course => onClick({id: "courseDetail", title: course.title, data: course})}/>
        case t('calendar_events'):
            return <EventsView onDateSelect={date => onClick({id: "show_events", title: t('events'), date: date})}/>
        case t('home_age'):
            return <CalculateAgeView/>
        case t('home_timer'):
            return <Stopwatch/>
        case t('events'):
            return <AddEventView onDone={onBack}
                                 onShowEvents={() => onClick({
                                     id: "show_events",
                                     event: true,
                                     title: t('events')
                                 })}/>
        case t('appointments'):
            return <AddAppointmentView onDone={onBack}
                                       onShowEvents={() => onClick({
                                           id: "show_events",
                                           title: t('appointments'),
                                           appointment: true
                                       })}/>
        case t('convert_date'):
            return <ConvertDateView/>
        case t('book_library'):
            return <BooksGridView
                key={"books"}
                onAdd={(test) => onClick({id: "upload_book", title: t('upload_book'), test: test})}/>
        case t('relaxing_music'):
            return <MusicListView/>
        case t('distribution_curve'):
            return <Curve/>
        case t('positive_message'):
            return <PositiveMessagesListView
                onClick={quote => onClick({id: "positive_message", title: t('positive_message'), data: quote})}/>
        case t('news'):
            return <NewsGridView path={"news"}
                                 key={'news'}
                                 onClick={news => onClick({id: "news_detail", title: news.title, data: news})}/>
        case t('psychological_test'):
            return <BooksGridView
                test={true}
                key={"tests"}
                onAdd={(test) => onClick({id: "upload_book", title: t('upload_book'), test: test})}/>

        case t('youtube_news'):
            return <NewsGridView path={"youtubeNews"}
                                 key={'youtube_news'}
                                 onClick={news => onClick({
                                     id: "news_detail",
                                     title: news.title,
                                     data: news,
                                     video: true
                                 })}/>
        case t('pdf'):
            return <NewsGridView
                key={'pdfNews'}
                path={"pdfNews"} onClick={news => onClick({
                id: "news_detail",
                title: news.title,
                data: news,
                pdf: true,
            })}/>
        case t('places'):
            return <NewsGridView path={"places"}
                                 key={'places'}
                                 onClick={news => onClick({
                                     id: "news_detail", title: news.title,
                                     place: true,
                                     data: news
                                 })}/>
        case t('contact_us'):
            return <ContactUs onDone={onBack}/>
        case t('notifications'):
            return <NotificationsListView/>
        case 'about_us':
            return <AboutView/>
        case 'email_input':
            return <EmailInputView
                onDone={(email) => onClick({
                    id: 'reset_password', title: t * 'reset_password',
                    data: email,
                    noHistory: true
                })}/>
        case 'reset_password':
            return <ResetPasswordView onDone={() => onClick(null)}
                                      email={item.data}/>
        default:
            return <span/>
    }
}

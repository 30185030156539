import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import "./i18n/i18n";
import "react-big-calendar/lib/css/react-big-calendar.css";

export const SERVER = 'https://psyapp.net:8443/api'
ReactDOM.render(
    <App/>,
    document.getElementById('root')
);

import React from "react";
import logo from '../../images/logo_text.PNG';
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";

const useStyle = makeStyles(theme => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        maxWidth: 700,
        textAlign: "center",
        margin: "0 auto",
    },
    logo: {
        maxWidth: 700,
        height: 300,
        width: '100%',
        objectFit: "contain",
    }
}));
export default function AboutView() {
    const {t} = useTranslation();
    const classes = useStyle();
    return (<div className={classes.root}>
            <img src={logo} alt={t('app_name')}
                 className={classes.logo}/>
            <br/>
            <br/>
            <br/>

            <Typography component={'span'} variant={'body1'}>
                {t('description_about_us')}
            </Typography>
            <br/>
            <br/>
            <Typography component={'h2'} variant={'h5'}>
                {t('application_by')}
            </Typography>
            <Typography component={'span'} variant={'h6'}>
                {t('owner_name')}
            </Typography>
            <br/>
            <Typography component={'span'} variant={'body2'} color={'textSecondary'}>
                {t('owner_profession')}
            </Typography>
            <br/>
            <br/>
            <br/>
            <Typography color={'textSecondary'}
                        component={'p'}
                        variant={'body2'}>
                {t('developed_by')}
            </Typography>
        </div>
    )
}
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import {accessToken, getUser} from "../../util/UserPrefs";
import {SERVER} from "../../index";
import {useMountEffect} from "../../util/Global";
import {Divider} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import {useTranslation} from "react-i18next";
import VideoIcon from '@material-ui/icons/Videocam';
import {PreviewPlayDialog} from "./PreviewPlayDialog";

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        maxWidth: '150ch',
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    thumb: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
    chipGroup: {
        marginTop: theme.spacing(1),
    },
    chip: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));

export default function CourseListView({onClick}) {
    const classes = useStyles();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState({open: false, course: null});
    const user = getUser();
    const {t} = useTranslation();

    useMountEffect(() => {
        if (user == null) {
            return;
        }
        setLoading(true);
        let headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken()}`);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER}/courses/getList/`, {
            headers: headers,
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(data => {
                setLoading(false);
                if (Array.isArray(data)) {
                    setCourses(data);
                }
            })
            .catch(e => {
                if (!aborted) {
                    setLoading(false);
                }
                console.log(e);
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    })

    const onPreviewClose = () => {
        setPreview({open: false, course: null});
    }

    return (
        <div className={classes.root}>
            {loading ? <CircularProgress size={100} color={'secondary'}/> :
                <List className={classes.list}>
                    {courses.map(course => (
                        <div key={course.id}>
                            <ListItem alignItems="flex-start"
                                      onClick={() => onClick(course)}
                                      key={course.id} button>
                                <ListItemAvatar>
                                    <Avatar alt={course.title}
                                            src={course.image}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<React.Fragment>
                                        <Typography
                                            component="span"
                                            variant="h5"
                                            className={classes.inline}
                                            color="textPrimary"
                                        >
                                            {course.title}
                                        </Typography>

                                    </React.Fragment>}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {course.description}
                                            </Typography>
                                            <div className={classes.chipGroup}>
                                                <Chip label={t('preview')}
                                                      onClick={(e) => {
                                                          e.stopPropagation();
                                                          setPreview({open: true, course: course});
                                                      }}
                                                      className={classes.chip}
                                                      icon={<VideoIcon/>}/>
                                                <Chip label={course.lecturerName} className={classes.chip}/>
                                                <PriceChip course={course} t={t} className={classes.chip}/>
                                            </div>
                                        </React.Fragment>
                                    }/>

                            </ListItem>
                            <Divider variant="inset" component="li"/>
                        </div>
                    ))}
                </List>
            }
            <PreviewPlayDialog open={preview.open}
                               course={preview.course}
                               onClose={onPreviewClose}/>
        </div>
    );
}

function PriceChip({course, t, className}) {
    if (course.price === 0) {
        return <Chip style={{backgroundColor: '#4caf50'}}
                     className={className}
                     label={t('free_course')}/>
    }
    return <Chip label={course.price} className={className}/>
}

import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import LogoView from "../common/view/LogoView";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Button, Typography} from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    formDiv: {
        maxWidth: 500,
        textAlign: "center",
        margin: "0 auto",
    },
    field: {
        margin: theme.spacing(2),
    },
    submitButton: {
        margin: theme.spacing(2),
        maxWidth: 300,
        color: '#FFF',
    }
}));
export default function CalculateAgeView() {
    const {t} = useTranslation();
    const classes = useStyles();
    const [day, setDay] = useState("");
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [result, setResult] = useState({text: "", error: false});

    const onChange = name => event => {
        if (name === "day") {
            setDay(event.target.value);
        } else if (name === "month") {
            setMonth(event.target.value);
        } else if (name === "year") {
            setYear(event.target.value);
        }
    };
    const onCalculate = () => {
        let d = day;
        try {
            d = Number(day);
        } catch (e) {
            setResult({text: t('error_birth_day'), error: true});
            return;
        }
        if (month === 0) {
            setResult({text: t('error_birth_month'), error: true});
            return;
        }
        if (year === 0) {
            setResult({text: t('error_birth_year'), error: true});
            return;
        }
        if (!isValidDay(d, month, year)) {
            setResult({text: t('error_birth_day'), error: true});
            return;
        }
        let b = moment(`${year}-${month}-${d}`, 'yyyy-MM-dd')
        let a = moment();
        let age = moment.duration(a.diff(b));
        let years = age.years();
        let months = age.months();
        let days = age.days();

        setResult({
            text: t('age_format_string')
                .replace("3", `${days}`)
                .replace("2", `${months}`)
                .replace("1", `${years}`), error: false
        })
    }
    return <div className={classes.root}>
        <LogoView title={t('home_age')}/>
        <div className={classes.formDiv}>
            <TextField variant={'standard'}
                       className={classes.field}
                       color={'secondary'}
                       value={day}
                       required
                       fullWidth
                       label={t('day')}
                       onChange={onChange("day")}
                       name={"day"}/>
            <FormControl className={classes.field}
                         required
                         fullWidth>
                <InputLabel id="month-select-label" color={'secondary'}>{t('select_month')}</InputLabel>
                <Select
                    labelId="month-select-label"
                    id="month-simple-select"
                    value={month}
                    fullWidth
                    onChange={onChange("month")}
                >
                    {getMonths(t).map(month => (
                        <MenuItem value={month.value} key={month.value}>{month.label}</MenuItem>))}
                </Select>
            </FormControl>
            <FormControl className={classes.field}
                         required
                         fullWidth>
                <InputLabel id="year-select-label" color={'secondary'}>{t('select_year')}</InputLabel>
                <Select
                    labelId="year-select-label"
                    id="year-simple-select"
                    value={year}
                    fullWidth
                    onChange={onChange("year")}
                >
                    {getYears(t).map(year => (
                        <MenuItem value={year.value} key={year.value}>{year.label}</MenuItem>))}
                </Select>
            </FormControl>
            <Typography variant={'body1'} color={result.error ? "error" : "textPrimary"}>{result.text}</Typography>

            <Button
                fullWidth
                onClick={onCalculate}
                className={classes.submitButton}
                color={'secondary'} variant={'contained'}>{t('home_age')}</Button>
        </div>
    </div>

}

function getMonths(t) {
    return [{value: 0, label: t('select_month')},
        {value: 1, label: t('january')},
        {value: 2, label: t('february')},
        {value: 3, label: t('march')},
        {value: 4, label: t('april')},
        {value: 5, label: t('may')},
        {value: 6, label: t('june')},
        {value: 7, label: t('july')},
        {value: 8, label: t('august')},
        {value: 9, label: t('september')},
        {value: 10, label: t('october')},
        {value: 11, label: t('november')},
        {value: 12, label: t('december')}];
}

function getYears(t) {
    let years = [{value: 0, label: t('select_year')}];
    for (let i = 2018; i > 1940; i--) {
        years.push({value: i, label: `${i}`});
    }
    return years;
}

function isValidDay(day, month, year) {
    if (day > 31) {
        return false;
    }
    switch (month) {
        case 1:
            return day > 0 && day < 32;
        case 2:
            return day > 0 && year % 4 === 0 ? day < 30 : day < 29;
        case 3:
            return day > 0 && day < 32;
        case 4:
            return day > 0 && day < 31;
        case 5:
            return day > 0 && day < 32;
        case 6:
            return day > 0 && day < 31;
        case 7:
            return day > 0 && day < 32;
        case 8:
            return day > 0 && day < 32;
        case 9:
            return day > 0 && day < 31;
        case 10:
            return day > 0 && day < 32;
        case 11:
            return day > 0 && day < 31;
        case 12:
            return day > 0 && day < 32;
        default:
            return false;
    }
}

import React from "react";
import EventIcon from '../../images/ic_add_event.png';
import AppointmentIcon from '../../images/ic_appointment.png';

export default function EventView({event}) {
    return <div>
        <img alt={event.title}
             src={event.phone !== null && event.phone !== undefined && event.phone.length > 3 ? AppointmentIcon : EventIcon}
             width={30} height={30}/>
    </div>
}
import React, {useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import LanguageIcon from '@material-ui/icons/Translate';
import Button from "@material-ui/core/Button";
import DropdownIcon from '@material-ui/icons/ExpandMore';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AppDrawer from "./Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import CenteredGrid from "./HomeLayout";
import BackIcon from '@material-ui/icons/ArrowBack';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import AppsIcon from '@material-ui/icons/Apps';
import {getUser} from "../util/UserPrefs";
import LoginDialog from "../user/LoginDialog";
import {useMountEffect} from "../util/Global";
import CurrentView from "./CurrentView";
import AppsDrawer from "./AppsDrawer";

const useStyles = makeStyles((theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
})));

export default function ActionBar() {
    const classes = useStyles();
    const [languageAnchor, setLanguageAnchor] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(null);
    const [items, setItems] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const {t, i18n} = useTranslation();
    const [loginOpen, setLoginOpen] = useState(false);
    const [appsDrawerOpen, setAppDrawerOpen] = useState(false);

    const changeLanguage = lng => {
        if (lng === i18n.language) {
            return;
        }
        i18n.changeLanguage(lng);
        handleLanguageMenuClose()
        if (lng === 'ar') {
            localStorage.setItem("dir", 'rtl');
        } else {
            localStorage.setItem('dir', 'ltr');
        }
        window.location.reload(null);
    };

    const handleLanguageMenuClose = () => {
        setLanguageAnchor(null);
    }
    const handleLanguageMenuOpen = event => {
        setLanguageAnchor(event.currentTarget);
    }

    const openDrawer = () => {
        setDrawerOpen(true)
    }

    const toggleAppsDrawer = open => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setAppDrawerOpen(open)
    }
    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open)
    };

    const goBack = () => {
        if (items.length > 1) {
            setCurrentItem(items[items.length - 2]);
            items.splice(items.length - 1, 1);
            setItems(items);
        } else {
            items.splice(0, items.length);
            setItems(items);
            setCurrentItem(null);
        }
    }
    const goForward = (item) => {
        setCurrentItem(item);
    }
    const onBackPress = () => {
        window.history.back();
    }
    const onLoginResponse = () => {
        setLoginOpen(false);
    }

    const onItemChange = item => {
        if (item == null) {
            setItems([])
            setCurrentItem(null);
            return;
        }
        const user = getUser();
        if (item.requireLogin && user == null) {
            setLoginOpen(true);
            return;
        }
        if (!item.noHistory) {
            if (currentItem != null && currentItem.title !== item.title) {
                if (items.filter(i => i.title === item.title).length === 0) {
                    items.push(item);
                    setItems(items);
                }
            } else {
                items.push(item);
                setItems(items);
            }
        }
        setAppDrawerOpen(false);
        window.history.pushState(item, item.title)
        goForward(item);
    };
    useMountEffect(() => {
        browserNavHandler(goBack);
        if (i18n.language.includes("ar")) {
            if (i18n.language !== "ar") {
                changeLanguage("ar")
            }
        } else if (i18n.language !== "en") {
            changeLanguage("en")
        }
    })

    const onForgotPasswordClick = () => {
        setDrawerOpen(false);
        onItemChange({id: 'email_input', title: t('reset_password'), noHistory: true})
    }
    const onAboutClick = () => {
        setDrawerOpen(false);
        onItemChange({id: "about_us", title: t('about_us')})
    }
    return (
        <div>
            <AppBar position="static">
                <Toolbar variant={"dense"}>
                    <IconButton onClick={currentItem == null ? openDrawer : onBackPress}>
                        {currentItem == null ? <MenuIcon/> :
                            (localStorage.getItem('dir') === "rtl" ? <ForwardIcon/> : <BackIcon/>)}
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {currentItem == null ? t('app_name') : currentItem.title}
                    </Typography>
                    <Button color="secondary"
                            endIcon={<DropdownIcon/>}
                            onClick={handleLanguageMenuOpen}
                            startIcon={<LanguageIcon/>}>
                        {t(i18n.language)}
                    </Button>
                    {currentItem != null && <IconButton onClick={() => setAppDrawerOpen(true)}>
                        <AppsIcon/>
                    </IconButton>}
                </Toolbar>
            </AppBar>
            <AppDrawer open={drawerOpen}
                       onAboutClick={onAboutClick}
                       onForgotPasswordClick={onForgotPasswordClick}
                       toggleDrawer={toggleDrawer}/>
            <AppsDrawer open={appsDrawerOpen}
                        toggleDrawer={toggleAppsDrawer}
                        onChange={onItemChange}/>
            {languageMenu(languageAnchor, handleLanguageMenuClose, t, i18n.language, changeLanguage)}
            <main className={classes.content}>
                {currentItem == null ?
                    <CenteredGrid onChange={onItemChange}/> : CurrentView(currentItem, t, onItemChange, onBackPress)}
            </main>
            <LoginDialog open={loginOpen} onResponse={onLoginResponse}/>
        </div>
    );
}

function browserNavHandler(onBack) {
    window.addEventListener('popstate', function () {
        onBack();
    }, false);
}


function languageMenu(anchorEl, handleMenuClose, t, currentLang, handleChange) {
    return <Menu
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id={'language-select'}
        keepMounted
        transformOrigin={{vertical: 'top', horizontal: 'right'}}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        <MenuItem selected={currentLang === "en"}
                  onClick={() => handleChange('en')}>{t('en')}</MenuItem>
        <MenuItem selected={currentLang === "ar"}
                  onClick={() => handleChange("ar")}>{t('ar')}</MenuItem>
    </Menu>;
}


import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import {useMountEffect} from "../util/Global";
import {Divider} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SERVER} from "../index";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import PlayingIcon from "@material-ui/icons/Lens";

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        maxWidth: '150ch',
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
    data: {
        maxWidth: 600,
        textAlign: "center",
        margin: "0 auto",
    }

}));

export default function MusicListView() {
    const classes = useStyles();
    const [music, setMusic] = useState([]);
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(null);

    useMountEffect(() => {
        setLoading(true);
        const abortController = new AbortController();
        let aborted = false;
        fetch(`${SERVER}/audios/get?unpaged=true`, {
            signal: abortController.signal
        })
            .then(response => {
                return response.json();
            })
            .then(page => {
                console.log(page);
                setLoading(false);
                if (Array.isArray(page.content)) {
                    setMusic(page.content);
                }
            })
            .catch(e => {
                console.log(2);
                if (!aborted) {
                    setLoading(false);
                }
            });
        return function cleanup() {
            aborted = true;
            abortController.abort();
        }
    })

    return (
        <div className={classes.root}>
            {current && <div className={classes.data} dangerouslySetInnerHTML={{__html: current.url}}/>}
            <div className={classes.data}>
                {loading ? <CircularProgress size={100} color={'secondary'}/> :
                    <List className={classes.list}>
                        {music.map(music => (
                            <div key={music.id}>
                                <ListItem alignItems="flex-start"
                                          onClick={() => setCurrent(music)}
                                          key={music.id} button>
                                    <ListItemText
                                        primary={<React.Fragment>
                                            <Typography
                                                component="span"
                                                variant="h5"
                                                className={classes.inline}
                                                color="textPrimary"
                                            >
                                                {music.name}
                                            </Typography>

                                        </React.Fragment>}/>
                                    {current != null && current.id === music.id &&
                                    <ListItemSecondaryAction color={'secondary'}>
                                        <IconButton edge="end" aria-label="playing" color={'secondary'}>
                                            <PlayingIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                    }
                                </ListItem>
                                <Divider component="li"/>
                            </div>
                        ))}
                    </List>
                }
            </div>

        </div>
    );
}
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import {useTranslation} from "react-i18next";
import CourseIcon from '../images/ic_courses.png';
import CalendarIcon from '../images/ic_today.png';
import AgeIcon from '../images/ic_history.png';
import StopwatchIcon from '../images/ic_stopwatch.png';
import EventIcon from '../images/ic_add_event.png';
import AppointmentIcon from '../images/ic_appointment.png';
import ConvertIcon from '../images/ic_convert_date.png';
import LibraryIcon from '../images/ic_books.png';
import NewsIcon from '../images/ic_newspaper.png';
import SoundIcon from '../images/ic_relaxing_music.png';
import DistributionIcon from '../images/ic_bar_chart.png';
import PositiveIcon from '../images/ic_idea.png';
import TestIcon from '../images/ic_outline_library_books.png';
import VideoIcon from '../images/ic_video_library.png';
import TopicIcon from '../images/ic_picture_as_pdf.png';
import PlacesIcon from '../images/ic_place.png';
import ContactIcon from '../images/ic_contact_us.png';
import NotificationIcon from '../images/ic_outline_notifications.png';
import logo from '../images/logo_text.PNG';

import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        maxWidth: 1000,
        width: '100%'
    },
    paper: {
        margin: 10,
        userSelect: 'none'
    },
    titleDiv: {
        marginTop: 10,
        background: theme.palette.secondary.main,
    },
    iconDiv: {
        height: 150,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontSize: 'medium',
        fontWeight: "bold",
        lineHeight: '50px',
        color: 'white',
        textOverflow: "ellipsis",
        overflow: 'hidden',
        maxHeight: 50,
        display: 'block',
    },
    paperDiv: {
        textAlign: 'center',
        alignContent: 'center'
    },
    icon: {
        width: 50,
        height: 50,
    },
    logo: {
        maxWidth: 700,
        height: 250,
        width: '100%',
        objectFit: "contain",
    },
    logoCard: {
        borderRadius: 10,
        height: '90%',
        textAlign: 'center',
        margin: theme.spacing(2)
    }
}));


function getData(t) {
    return [
        {title: t('calendar_events'), img: CalendarIcon, requireLogin: true},
        {title: t('home_age'), img: AgeIcon},
        {title: t('home_timer'), img: StopwatchIcon},
        {title: t('events'), img: EventIcon, requireLogin: true},
        {title: t('appointments'), img: AppointmentIcon, requireLogin: true},
        {title: t('convert_date'), img: ConvertIcon},
        {title: t('book_library'), img: LibraryIcon, requireLogin: true},
        {title: t('relaxing_music'), img: SoundIcon},
        {title: t('distribution_curve'), img: DistributionIcon},
        {title: t('positive_message'), img: PositiveIcon},
        {title: t('news'), img: NewsIcon},
        {title: t('psychological_test'), img: TestIcon, requireLogin: true},
        {title: t('youtube_news'), img: VideoIcon},
        {title: t('pdf'), img: TopicIcon},
        {title: t('places'), img: PlacesIcon},
        {title: t('contact_us'), img: ContactIcon},
        {title: t('notifications'), img: NotificationIcon}
    ]
}

export default function HomeLayout({onChange, sideBar}) {
    const classes = useStyles();
    const {t} = useTranslation();
    const tileData = getData(t);
    return (
        <div className={classes.root}>
            <GridList cellHeight={280} className={classes.gridList} spacing={3} cols={2}>
                {!sideBar && <GridListTile key={'app_logo'} cols={2}>
                    <Card className={classes.logoCard}>
                        <img src={logo} alt={t('app_name')} className={classes.logo}/>
                    </Card>
                </GridListTile>}
                {tileData.map((tile) => (
                    <GridListTile key={tile.img} cols={1} onClick={() => onChange(tile)}>
                        <Paper className={classes.paper}>
                            <div className={classes.paperDiv}>
                                <div className={classes.iconDiv}>
                                    <img className={classes.icon} src={tile.img} alt={tile.title}/>
                                </div>
                                <div className={classes.titleDiv}>
                                    <span className={classes.title}>{tile.title}</span>
                                </div>
                            </div>
                        </Paper>
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}

import React, {useState} from 'react';
import {Button, Dialog, DialogActions, FormControl, Input} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import {SERVER} from "../index";
import {saveUser} from "../util/UserPrefs";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

export default function LoginDialog({open, onResponse, onForgotPassword}) {
    const {t} = useTranslation();
    const [error, setError] = useState(null);
    const [errorUsername, setErrorUsername] = useState(null);
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {

        }
    };
    const handleChange = name => (event) => {
        if (name === 'password') {
            let val = event.target.value;
            setPassword(val);
        } else if (name === 'username') {
            let val = event.target.value;
            setUsername(val);
            setErrorUsername(null);
        }
    };
    const handleLogin = () => {
        if (username.length < 1) {
            setErrorUsername(t('error_enter_username'))
            return;
        }
        if (password.length < 1) {
            setError(t('error_enter_password'))
            return;
        }
        setLoading(true);
        const body = {
            phone: username,
            password: password
        }
        const paramsArray = [];
        for (let prop in body) {
            let encodedKey = encodeURIComponent(prop);
            let encodedValue = encodeURIComponent(body[prop]);
            paramsArray.push(encodedKey + "=" + encodedValue);
        }
        const params = paramsArray.join("&");
        fetch(`${SERVER}/users/login`, {
            method: "POST",
            body: params,
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}
        })
            .then(response => response.json())
            .then(user => {
                setLoading(false)
                saveUser(user)
                onResponse(true)
            }).catch(e => {
            setLoading(false)
            setError(t('invalid_login'))
        })

    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
            <DialogTitle
                id="form-dialog-title">{t('login')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("login_message")}
                </DialogContentText>
                <TextField
                    fullWidth
                    error={errorUsername}
                    style={{marginTop: '1em', marginBottom: '1em'}}
                    id={"username"}
                    label={t("phone_number_or_email")}
                    value={username}
                    color={"secondary"}
                    disabled={loading}
                    onChange={handleChange('username')}
                    required/>
                <FormControl style={{marginBottom: '1em'}} fullWidth required>
                    <InputLabel>{t('password')}</InputLabel>
                    <Input
                        id={"password"}
                        color={'secondary'}
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        error={error}
                        disabled={loading}
                        onChange={handleChange('password')}/>
                    <FormHelperText id="error-helper-text">{error}</FormHelperText>
                </FormControl>
                <Button color={'secondary'} variant={'text'} onClick={onForgotPassword}>
                    {t('forgot_pass')}
                </Button>
            </DialogContent>
            <DialogActions>
                <Button disabled={loading}
                        variant={'text'} color={'secondary'}
                        onClick={() => {
                            onResponse(null, false);
                        }}>
                    {t("cancel")}
                </Button>
                <Button disabled={loading} variant={'text'}
                        color={'secondary'}
                        onClick={handleLogin}>
                    {loading && (
                        <CircularProgress color={"secondary"} size={25} thickness={2}/>
                    )}
                    {t("login")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
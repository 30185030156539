export default {
    app_name: "Psychologist Assistant",
    en: "English",
    ar: "Arabic",
    about_us: "About",
    logout: "Logout",
    courses: 'Courses',
    calendar_events: "Calendar",
    home_age: "Calculate Age",
    home_timer: "Stopwatch",
    events: "Events",
    appointments: "Appointments",
    todo: "To-Do",
    convert_date: "Convert Date",
    book_library: "My Library",
    relaxing_music: "Relaxing Sounds",
    distribution_curve: "Distribution Curve",
    positive_message: "Positive Message",
    news: "News",
    psychological_test: "My Psychological Tests",
    youtube_news: "Videos",
    pdf: "Psychological Topics",
    places: "Important Places",
    notifications: "Notifications",
    contact_us: "Technical Support",
    login: "Login",
    password: "Password",
    phone_number_or_email: "Phone number or email",
    cancel: "Cancel",
    login_message: "Please input your credentials to login. If you do not have account you can register.",
    error_enter_password: "Please input your password",
    error_enter_username: "Please input your username",
    invalid_login: "Invalid username or password.",
    logout_message: "Do you really want to logout?",
    signup: "Sign Up",
    first_name: "Your Name",
    phone_number: "Phone Number",
    email: "Email",
    confirm_password: "Confirm Password",
    select_country: "Select Country",
    select_city: "Select City",
    signup_message: "Please use valid phone number and email address for sign up.",
    error_input_name: "Please input your name",
    error_input_confirm_password: "Confirm password does not match",
    error_input_phone: "Please input valid phone number",
    error_input_email: "Please input valid email address",
    error_select_country: "Please select your country",
    error_select_city: "Please select your city",
    unknown_error: "Unknown error occurred",
    free_course: "Free",
    preview: "Preview",
    day: "Day",
    select_year: "Select year",
    select_month: "Select month",
    error_birth_year: "Please input birth year",
    error_birth_day: "Please input birth day",
    error_birth_month: "Please input birth month",
    invalid_birth_date: "Date of birth is not valid",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    lap: "Lap",
    event_name: "Event name",
    event_description: "Event description (Optional)",
    select_date_of_event: "Date of event",
    select_time_of_event: "Event time",
    error_event_name: "Please input name",
    unable_add_event: "Unable to add event. Please check your internet connection",
    event_added_success: "Event added successfully",
    add_event: "Add event",
    show_events: "Show events",
    add_appointment_success: "Appointment Successfully add.",
    add_appointment: "Add appointment",
    error_patient_name: "Please enter patient name",
    error_select_date: "Please select appointment date",
    error_appointment_node: "Please input appointment note",
    patient_name: "Patient Name",
    number: "Number",
    select_date: "Select Date",
    select_time: "Select Time",
    appointment_description: "Appointment description",
    show_appointments: "Show appointments",
    select_hijri_date: "Convert Hijri date",
    select_gregorian_date: "Convert Gregorian date",
    upload_book: "Upload book",
    note_upload_file: "Only Portable Document File (PDF) can be uploaded.",
    error_book_file: "Please select book file",
    success_book_uploaded: "Book uploaded successfully.",
    error_book_name: "Please input book name.",
    select_book_file: "Select book file",
    error_book_upload: "Unable to add your book. Please try again and make sure your book is less than 10MB in size",
    book_name: "Book name",
    upload_psy_test: "Upload Psychological test",
    psy_test_name: "Test name",
    select_psy_test_file: "Select test file",
    error_psy_test_upload: "Unable to add your test. Please try again and make sure your test is less than 10MB in size.",
    error_psy_test_name: "Please input psychological test name.",
    success_psy_test_uploaded: "Test uploaded successfully.",
    your_feedback: "Your feedback",
    send_feedback: "Send feedback",
    thanks_feedback: "Thank you for your feedback",
    message_write_feedback: "Please write your feedback",
    description_about_us: "Psychologist Assistant is an App that helps psychologist to " +
        "organize his/her work through many tools and also provides them with the essential tools that they need in their work",
    application_by: "Application By",
    owner_name: "Reem Abdulrazzak",
    owner_profession: "Psychologist",
    developed_by: "Developed with 💖 by MASSARTTECH http://massarttech-apps.com/",
    ok: "OK",
    open_pdf: "Open PDF",
    close: "Close",
    already_registered: "Email is already registered.",
    age_format_string: "1 year(s) 2 month(s) 3 day(s)",
    no: "No",
    yes: "Yes",
    do_you_want_to_delete_this_book: "Do you want to delete this book?",
    deleteBook: "Delete",
    do_you_want_to_delete_this_test: "Do you want to delete this test?",
    no_books: "You have not uploaded any books.",
    no_tests: "You have not uploaded any psychological tests",
    forgot_pass: "Forgot Password?",
    error_reset_password: "No account found with given email.",
    resend_code_reset: "A code has been sent to your email. Please use that code to reset you password",
    password_updated: "Password updated.",
    dialog_message: "Message",
    error_invalid_opt: "Invalid verification code.",
    account_verified: "Account verified.",
    message_code_resend: "A code has been sent to your mobile.",
    reset_password: "Reset Password",
    new_password: "New Password",
    confirm_new_password: "Confirm New Password",
    save: "Save",
    enter_otp: "Enter verification code",

}